import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import { Box } from '@mui/material';
import AuthService from '../../../service/AuthService';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { currencyFormat } from '../../../util/formatter'
import AddIcon from '@mui/icons-material/Add';
import { getTipoImportacaoBySigla } from '../../../util/board/BoardUtil';
import CloseIcon from '@mui/icons-material/Close';
import { getBenefitsApp, pendingAmount, totalOrder } from '../../../util/order/orderUtil';
import { useSnackbar } from 'notistack';
import { Alert } from '@mui/material';

const PREFIX = 'OrderImportPayments'

const classes = {
  scrollBar: `${PREFIX}-scrollBar`,
  productFounded: `${PREFIX}-productFounded`,
  productNotFound: `${PREFIX}-productNotFound`,
  rootTableRow: `${PREFIX}-rootTableRow`
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  [`& .${classes.scrollBar}`]: theme.scrollBar,

  [`& .${classes.productFounded}`]: {
    color: theme.palette.success.main,
  },

  [`& .${classes.productNotFound}`]: {
    color: theme.palette.error.main,
  },

  [`& .${classes.rootTableRow}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  }
}))

export default function OrderImportPayments({ order, setOrder }) {
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])
  const [paymentList, setPaymentList] = useState(order?.iFoodOrder?.payments || [])
  const pedidoDeIntegracao = getTipoImportacaoBySigla(order?.tipoImportacao).integracao
  const [benefitApp, setBenefitApp] = useState(0) //valor dos descontos fornecidos pelo aplicativo

  const totalPago = paymentList.reduce((total, payment) => total + parseFloat(payment.value || 0), 0)
  const totalPedido = totalOrder(order.iFoodOrder)
  const podeAddPagamento = totalPago < totalPedido

  console.log('totalPago', totalPago)

  useEffect(() => {
    setLoading(true)

    setBenefitApp(getBenefitsApp(order?.iFoodOrder))

    let service = new AuthService()
    service.get('/service?action=list&entity=formaPagamento')
      .then(formaDePagamentoList => {
        setLoading(false)
        /* REMOVE FORMAS PGTO INATIVAS */
        if (formaDePagamentoList) {
          formaDePagamentoList = formaDePagamentoList.filter(elem => elem.ativo)
        }
        setOptions(formaDePagamentoList)

      }).catch(err => {
        console.error(err)
        setLoading(false)
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let orderCopy = Object.assign({}, order)
    orderCopy.iFoodOrder.payments = paymentList
    setOrder(orderCopy)
    // eslint-disable-next-line
  }, [paymentList])

  const addPayment = () => {
    let paymentsCopy = Object.assign([], paymentList)

    paymentsCopy.push({
      name: "Novo pagamento",
      value: parseFloat(pendingAmount(order.iFoodOrder)),
      openSelect: true
    })

    setPaymentList(paymentsCopy)

  }

  const handleChangePayment = () => {
    let paymentsCopy = Object.assign([], paymentList)
    setPaymentList(paymentsCopy)
  }

  const handleRemovePayment = (index) => {
    let newList = paymentList.filter((payment, i) => i !== index);
    setPaymentList(newList)
  }

  return (
    <Box mt={2}>

      {/** <Typography variant="h6" component="div">Pagamentos</Typography> */}
      <TableContainer >
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "60%" }}>Pagamentos</TableCell>
              <TableCell align="right" style={{ width: "75px" }}>Valor</TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {paymentList.map((payment, index) => (

              <ImportPaymentTableRow
                key={index}
                payment={payment}
                loading={loading}
                options={options}
                handleChangePayment={handleChangePayment}
                handleRemovePayment={handleRemovePayment}
                order={order}
                index={index}
                disabledEdit={!pedidoDeIntegracao} />

            ))}
          </TableBody>

        </Table>
      </TableContainer>

      {/*mostra o campo com valor do desconto fornecido pelo app caso tenha descontos e o desconto do aplicativo seja maior que 0 */}
      {benefitApp > 0 && (
        <Alert severity="info" color="info">
          {`Será adicionado automaticamente um pagamento de ${currencyFormat(benefitApp)} referente ao desconto do ${getTipoImportacaoBySigla(order.tipoImportacao).descricao}.`}
        </Alert>
      )}

      <Box pb={2} display="flex" flexDirection="row-reverse">
        {pedidoDeIntegracao && podeAddPagamento && (
          <Button
            color="grey"
            startIcon={<AddIcon />}
            onClick={addPayment}
            size="small">Adicionar pagamento</Button>
        )}
      </Box>

    </Box>
  )
}

export const handleClickVerificarPagamento = (payment, order) => {
  //recebe o método de pagamento e verifica se a descrição dele não é vazia e o valor dele não é zero
  if (payment.formaPagamento !== null && payment.value > 0 && payment.formaPagamento !== undefined) {
    return true // retorna true quando está tudo certo
  } else if (payment.formaPagamento === null || payment.formaPagamento === undefined) {
    return undefined // retorna undefined quando a descrição é vazia
  } else {
    const totalOfThisOrder = totalOrder(order)
    if (totalOfThisOrder <= 0.009 && payment.value === 0.0) {
      return true;
    }
    return false // retorna false quando o valor é menor que zero
  }

  //esses valores de retorno são utilizados onde a função é chamada para editar os snackBarse apresentar a mensagem para o usuário
}

function ImportPaymentTableRow({ payment, loading, options, handleChangePayment, handleRemovePayment, disabledEdit, index, order }) {

  const [openSelect, setOpenSelect] = useState(payment.openSelect)
  const [selectedPayment, setSelectedPayment] = useState(payment.formaPagamento || {})
  const itemHasLinked = payment.formaPagamento !== null;
  const { enqueueSnackbar } = useSnackbar()

  const handleClickAlterar = () => { //utilizada para alterar a exibição na tela entre edição e fixo

    if (openSelect) { //caso openselect esteja aberto(editando pagamento) é feita uma verificação para ver se o pagamento é válido
      let verificado = handleClickVerificarPagamento(payment, order)
      if (verificado) {
        setOpenSelect(!openSelect)
      } else if (verificado === false) {
        enqueueSnackbar("Não é possível adicionar pagamentos com valor igual ou menor que zero", { variant: "error" });
      } else {
        enqueueSnackbar("Não é possível adicionar pagamentos com descrição vazia", { variant: "error" });
      }
    } else {//caso o openselect esteja definido como false altera seu valor para true para poder editar o método de pagamento
      setOpenSelect(!openSelect)
    }

  }

  return (
    <StyledTableRow>
      <TableCell style={{ width: "45%" }}>

        {!openSelect && (
          <Typography className={itemHasLinked ? classes.productFounded : classes.productNotFound}>
            {payment.formaPagamento?.descricao}
          </Typography>
        )}

        {openSelect &&
          <Autocomplete
            size="small"
            loading={loading}
            options={options}
            value={selectedPayment}
            isOptionEqualToValue={(option, value) => {
              return option && option.id === value.id
            }}
            onChange={(event, newValue) => {
              setSelectedPayment(newValue);
              payment.formaPagamento = newValue
            }
            }
          renderOption={(props, option) => <li {...props} key={option.id}>{option.descricao}</li>}
            getOptionLabel={(option) => option.descricao || ""}
            renderInput={(params) => <TextField {...params} />}
          />
        }
      </TableCell>

      <TableCell scope="row" align="right" style={{ width: "200px" }}>
        {openSelect ?
          <TextField type="number"
            fullWidth
            size="small"
            variant="outlined"
            value={payment.value}
            onChange={(event) => {
              payment.value = event.target.value;
              handleChangePayment()
            }
            }
            inputProps={{
              min: 0
            }}
          />
          :
          currencyFormat(payment.value)
        }

      </TableCell>



      <TableCell padding="checkbox" size='small'>
        {disabledEdit ? (
          null
        ) : (
          <IconButton
            title="Alterar forma de pagamento associada"
            onClick={handleClickAlterar}
            size="large">
            {openSelect ? <CheckIcon /> : <EditIcon />}
          </IconButton>
        )}
      </TableCell>

      <TableCell padding="checkbox" size='small'>
        {disabledEdit ? (
          null
        ) : (
          <IconButton
            title="Excluir forma de pagamento"
            onClick={() => handleRemovePayment(index)}
            size="large">
            <CloseIcon />
          </IconButton>
        )}
      </TableCell>

    </StyledTableRow>
  )
}